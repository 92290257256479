const data = {
  text: 'Jetzt Erstgespräch vereinbaren',
  subline: 'Hier klicken, um Termin zu vereinbaren',
  link: '100% kostenlos & unverbindlich',
  url: {
    to: '/termin-buchen',
    internalLink: true
  }
}

export default data
