import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const imageContainerClassName = 'column is-12-mobile is-6-tablet'
const imageContainerConfig = {
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0'
  },
  defaultCss: {
    textAlign: 'center'
  }
}
const imageConfig = {
  width: {
    desktop: '200px',
    tablet: '200px',
    mobile: '60%'
  },
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    borderRadius: '50%'
  }
}
const titleConfig = {
  size: 4
}
const descriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    fontStyle: 'italic'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '0',
        widescreen: '0',
        desktop: '0',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Zufriedene Kunden',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: imageContainerClassName,
              ...imageContainerConfig,
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/reviews/simone-grimmeisen.jpg',
                    ...imageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: 'Simone Grimmeisen',
                    ...titleConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Vielen Dank für diese atemberaubende Arbeit meiner Augenbrauen. Diese ist so natürlich und erspart mir morgens das lästige Schminken. Endlich habe ich perfekt geformte und symmetrische Augenbrauen.“',
                    ...descriptionConfig
                  }
                }
              ]
            },
            {
              classNames: imageContainerClassName,
              ...imageContainerConfig,
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/reviews/simone-grimmeisen.jpg',
                    /*src: '/images/reviews/christa-moessner.jpg',*/
                    ...imageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: 'Christa Mössner',
                    ...titleConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Durch das Tragen meiner Brille, wurde mich das zeichnen meiner Augenbrauen immer mehr erschwert. Ich bin sehr glücklich, diesen Schritt eines Permanent Make-ups gegangen zu sein.“',
                    ...descriptionConfig
                  }
                }
              ]
            },
            {
              classNames: imageContainerClassName,
              ...imageContainerConfig,
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/reviews/brigitte-talmon.jpg',
                    ...imageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: 'Brigitte Talmon - Gross',
                    ...titleConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Dank der Arbeit von Frau Beuther, leuchten meine Augen noch viel mehr. Die Augenbrauen haben meinem Gesicht einen Rahmen gegeben. Ich fühle mich extrem wohl in meiner Haut und würde diesen Schritt jeder Zeit wieder tun. Alle Angst und Zweifel waren überflüssig, da Frau Beuther meine Erwartungen bei weitem übertroffen hat.“',
                    ...descriptionConfig
                  }
                }
              ]
            },
            {
              classNames: imageContainerClassName,
              ...imageContainerConfig,
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/reviews/andrea-wiedemann.jpg',
                    ...imageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: 'Andrea Wiedemann',
                    ...titleConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Leider hatte Ich mit der Zeit stark an Augenbrauen Härchen verloren. Auf der Suche nach einer natürlichen Behandlung, meine Härchen zu Rekonstruieren, bin ich auf Frau Beuther gestossen. Sie hat mir meine alten Augenbrauen wieder geschenkt und sogar noch optimiert und die Symmetrie verbessert und korrigiert. Ich bin Ihr so unglaublich Dankbar für diese tolle Beratung und sorgfältige Arbeit.“',
                    ...descriptionConfig
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
