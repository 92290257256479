import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const descriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: '#f7f4f4'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px 0',
        mobile: '60px 20px 0'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-5-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/julia-beuther.png',
                    width: '100%'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-7-desktop pl-0 pr-0',
              containers: [
                {
                  padding: {
                    desktop: '40px',
                    tablet: '40px',
                    mobile: '0 20px 40px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 3,
                        defaultCss: {
                          textAlign: 'left'
                        },
                        text: 'Deine persönliche Ansprechpartnerin'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '20px 0 30px',
                          tablet: '20px 0 30px',
                          mobile: '20px 0 30px'
                        }
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Ich bin Julia Beuther, staatlich anerkannte Kosmetikerin und Gründerin des JB.Cosmetics Kosmetiksalons.',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Mein Ziel ist es, meinen Kunden stets die höchste Zufriedenheit und die besten Ergebnisse zu bieten.',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Ich liebe und lebe Schönheit mit voller Leidenschaft.',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        ...ctaButton,
                        margin: {
                          desktop: '50px 0 0',
                          tablet: '50px 0 0',
                          mobile: '30px 0 0'
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
