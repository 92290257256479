import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: 'url("/images/hero.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(255, 255, 255, .90)'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '120px 0 80px',
            widescreen: '120px 0 80px',
            desktop: '120px 40px 60px',
            tablet: '120px 40px 60px',
            mobile: '110px 20px 40px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          textAlign: 'center',
                          color: colors.header
                        },
                        fontSize: {
                          desktop: '18px',
                          tablet: '14px',
                          mobile: '12px'
                        },
                        margin: {
                          desktop: '0 0 10px',
                          tablet: '0 0 5px',
                          mobile: '0 30px 20px'
                        },
                        text:
                          'Schluss mit lästigem Schminken und schlechter Laune am frühen Morgen'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        defaultCss: {
                          textAlign: 'center'
                        },
                        margin: {
                          fullHd: '0 100px 30px',
                          widescreen: '0 100px 30px',
                          desktop: '0 0 30px',
                          tablet: '0',
                          mobile: '0 10px'
                        },
                        fontSize: {
                          desktop: '32px',
                          tablet: '28px',
                          mobile: '18px'
                        },
                        text:
                          'Endlich natürlich schön durch perfekt sitzende Augenbrauen - immer und überall'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto 10px',
                          tablet: '30px auto 0',
                          mobile: '20px auto 0'
                        }
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/332851221',
                        color: colors.primary
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          'Du bist müde vom ständigen früh aufstehen, um am morgen alles zu schaffen und dabei auch noch gut auszusehen? Schluss damit! Dank natürlichem permanent Make-Up kannst du ab sofort  sorglos und stressfrei in jeden Tag starten!',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Zeitersparnis:</b> Endlich Schluss mit extra frühem Aufstehen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Mehr Selbstbewusstsein</b> durch perfekt sitzende Augenbrauen, direkt nach dem Aufstehen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Natürlicher Look</b> dank besonders präziser Handarbeit',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '10px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
