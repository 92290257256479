import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemTitleConfig = {
  size: 4,
  defaultCss: {
    color: '#fff',
    textAlign: 'center'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const itemSubTitleConfig = {
  size: 4,
  defaultCss: {
    color: '#fff',
    textAlign: 'center'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    color: '#fff',
    textAlign: 'center'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    background: '#B48C86'
  },
  margin: {
    desktop: '60px 0 0',
    tablet: '60px 0 0',
    mobile: '60px 0 0'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#707070',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: '#f0ecec'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: '#fafafa'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,

            text: 'In nur 3 Schritten zu deinen perfekten Augenbrauen'
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'date_range',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Schritt 1',
                            ...itemTitleConfig
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: '#fff'
                            },
                            margin: {
                              desktop: '10px auto',
                              tablet: '10px auto',
                              mobile: '10px auto'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Termin sichern',
                            ...itemSubTitleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text:
                              'Vereinbare jetzt dein kostenloses Erstgespräch mit einem Experten aus unserem Team. Buche über den Terminkalender ganz einfach das Zeitfenster, welches für dich am besten passt.',
                            ...itemDescriptionConfig
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'call',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Schritt 2',
                            ...itemTitleConfig
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: '#fff'
                            },
                            margin: {
                              desktop: '10px auto',
                              tablet: '10px auto',
                              mobile: '10px auto'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Erstgespräch',
                            ...itemSubTitleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text:
                              'Bei deinem kostenlosen Erstgespräch kannst du alle Fragen stellen, die dir auf dem Herzen liegen. Ein Experte berät dich außerdem umfänglich und individuell bezüglich unserer Behandlungsmöglichkeiten.',
                            ...itemDescriptionConfig
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'favorite',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Schritt 3',
                            ...itemTitleConfig
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: '#fff'
                            },
                            margin: {
                              desktop: '10px auto',
                              tablet: '10px auto',
                              mobile: '10px auto'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Behandlungstermin',
                            ...itemSubTitleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text:
                              'Im Anschluss an unser Telefonat besuchst du uns zum vereinbarten Termin in unserem Salon und gehst nach ca. 2 Stunden Behandlungszeit mit einem Strahlen und perfekten Augenbrauen wieder nach Hause.',
                            ...itemDescriptionConfig
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
