import colors from '../colors'

const data = {
  /** Theme */
  theme: {
    primary: colors.primary
  },
  /** TagManager */
  // gtmID: 'GTM-TJX34NG',
  // gtmID: 'GTM-MR67XGH',
  gtmID: '',
  /** Calendly */
  calendly: {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: colors.primary,
      textColor: '000000'
    },
    url: 'https://calendly.com/jbcosmetics/15min',
    isPage: true
  }
}

export default data
