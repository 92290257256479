const general = {
  title: 'Permanent Make-Up » Julia Beuther Cosmetics',
  description: 'Immer perfekt geschminkt aussehen mit Permanent Make-up',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'images/share.png',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  imprint: {
    ...general,
    title: 'Impressum » Julia Beuther Cosmetics'
  },
  policy: {
    ...general,
    title: 'Datenschutz » Julia Beuther Cosmetics'
  },
  thankYou: {
    ...general,
    title: 'Danke » Julia Beuther Cosmetics'
  }
}

export default data
