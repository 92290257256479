import generalData from './data/general'
import colors from '../default/colors'
import { HeaderInterface } from '@peakconcepts/landingpage-kit/lib'

const header: HeaderInterface = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'unset'
    }
  },
  brandConfig: {
    className: 'ml-0',
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo.png',
      width: '130px',
      defaultCss: {
        maxHeight: 'unset !important'
      }
    }
  },
  menuEndConfig: {
    telButton: {
      padding: { mobile: '7px 8px 7px 7px' },
      text: '07361 / 8149906',
      defaultCss: { border: 'none !important', background: 'none' }
    }
  },
  burgerConfig: {
    hide: true
  }
}

const data = {
  config: {
    gtmID: generalData.gtmID,
    theme: {
      primary: generalData.theme.primary
    }
  },
  footer: {
    copyright: 'Copyright © 2021. Alle Rechte reserviert.',
    dark: false,
    backgroundColor: colors.footer,
    image: {
      src: '/images/logo.png',
      width: '120px'
    },
    text:
      'Hinweis: Das Angebot richtet sich in erster Linie an Geschäftskunden sowie an Gewerbetreibende & Unternehmen im Sinne des §14 BGB. This site is not a part of the Facebook TM website or Facebook TM Inc. Additionally, this site is NOT endorsed by FacebookTM in any way. FACEBOOK TM is a trademark of FACEBOOK TM, Inc.'
  },
  scrollToTop: {
    pixelFromEnd: 400
  },
  header,
  calendly: {
    pageSettings: {
      backgroundColor: generalData.calendly.pageSettings.backgroundColor,
      hideEventTypeDetails:
        generalData.calendly.pageSettings.hideEventTypeDetails,
      hideLandingPageDetails:
        generalData.calendly.pageSettings.hideLandingPageDetails,
      primaryColor: generalData.calendly.pageSettings.primaryColor,
      textColor: generalData.calendly.pageSettings.textColor
    },
    url: generalData.calendly.url
  }
}

export default data
