import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '0 16px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          defaultCss: {
            textAlign: 'center'
          },
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-6-desktop pl-0 pr-0 image-container',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/1.jpg'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-6-desktop is-flex',
              containers: [
                {
                  defaultCss: {
                    background: '#f7f4f4'
                  },
                  padding: {
                    desktop: '80px 40px',
                    tablet: '40px 20px',
                    mobile: '40px 20px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 3,
                        text: '„I woke up like this“'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto',
                          tablet: '30px auto',
                          mobile: '30px auto'
                        }
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text:
                          'Wie schön wäre es, das einmal sagen zu können - oder besser jeden Tag?'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Immer wieder werden wir Frauen mit Schauspielerinnen konfrontiert, die <b>nach dem Aufwachen</b> in Filmen <b>schöner</b> nicht sein könnten, während wir selbst morgens am Liebsten den Blick in den Spiegel meiden.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Direkt nach dem Aufwachen in den Spiegel schauen, und statt wirren <b>wunderschöne Augenbrauen</b> sehen. <br/><br/>Klingt wie ein <b>Traum</b>, ist es aber nicht!',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '<b>Permanent Make-Up</b> macht es möglich und <b>rettet</b> auch deinen morgendlichen Blick in den Spiegel, damit du endlich gut gelaunt und <b>wunderschön</b> in den Tag starten kannst.',
                        ...itemDescriptionConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
