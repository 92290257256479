import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
const descriptionConfig = {}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px 40px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Unser Salon',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-6',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/salon.jpg',
                    width: '100%'
                  }
                }
              ]
            },
            {
              classNames: 'column is-6',
              containers: [
                {
                  type: 'text',
                  config: {
                    text:
                      'Unser moderner Kosmetiksalon befindet sich inmitten von Aalen.',
                    ...descriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'In wunderschönen Räumlichkeiten betreuen wir unsere Kunden mit Leidenschaft und lassen Schönheit wahr werden.',
                    ...descriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Unser Team, bestehend aus staatlich geprüften Kosmetikerinnen, legt größten Wert auf Kundenzufriedenheit und die besten Ergebnisse.',
                    ...descriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Wir lieben und leben Schönheit, was man an unseren glücklichen Kunden erkennen kann.',
                    ...descriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Julia Beuther cosmetics<br/>Beinstraße 44<br/>73430 Aalen',
                    ...descriptionConfig
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
