import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import FAQs from '../../components/FAQs'

const data: ContainerBoxInterface = {
  defaultCss: {
    background: '#f7f4f4'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Häufig gestellte Fragen',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'component',
          component: FAQs,
          config: {
            items: [
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie lange halten die Augenbrauen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Haltbarkeit der Augenbrauen ist von einigen Faktoren wie Hauttyp, Alter und angewandter Technik abhängig. In der Regel sollten deine perfekten Augenbrauen allerdings 1-2 Jahre anhalten, bevor sie das nächste mal aufgefrischt werden sollten.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie lange dauert die Behandlung?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Behandlungszeit liegt bei ca. 1,5 - 2 Stunden schmerzfreier Behandlung, während du dich völlig entspannen und deinen Traum wahr werden lassen kannst.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie viel kostet die Behandlung?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Der Preis setzt sich zusammen aus Technik und Aufwand und wird somit individuell für jede Kundin errechnet. In einem kostenlosen Beratungsgespräch können wir auf deine Wünsche eingehen und den optimalen Behandlungsplan für dich erstellen.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Sieht man, dass die Augenbrauen gemacht sind?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Nein. Während unserer Behandlung perfektionieren wir deine natürliche Form in präziser Handarbeit, wodurch ein wunderschöner natürlicher Look kreiert wird.'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  ]
}

export default data
