import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '0 16px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          defaultCss: {
            textAlign: 'center'
          },
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-6-desktop is-flex',

              containers: [
                {
                  defaultCss: {
                    background: '#f7f4f4'
                  },
                  padding: {
                    desktop: '80px 40px',
                    tablet: '40px 20px',
                    mobile: '40px 20px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text:
                          'Weil es das Leben nicht nur leichter, sondern auch schöner macht'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto',
                          tablet: '30px auto',
                          mobile: '30px auto'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'modern gestaltete Räumlichkeiten',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'wunderbare <b>Verwöhnmomente</b>',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: '<b>schmerzfreie</b> Behandlung',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'staatlich geprüfte Kosmetik <b>Expertinnen</b>',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: '<b>Wunderwaffe</b> gegen schmale Augenbrauen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: '<b>natürliche Schönheit und Strahlen</b>',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: '<b>traumhaft</b> schöne Augenbrauen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Augenbrauen so <b>individuell</b> wie du',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: '<b>dauerhaft perfektionierter Look</b>',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'kein Schmieren und Verwischen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'hält <b>immer und überall</b> - selbst beim Schwitzen, Schwimmen und in der Sauna',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-6-desktop pl-0 pr-0 image-container',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/2.jpg'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
