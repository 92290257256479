import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Du sehnst dich schon ewig nach den perfekten Augenbrauen?',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '0 120px',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Dann klick jetzt auf den untenstehenden Button und buche dein kostenloses Erstgespräch mit einer unserer Schönheits Expertinnen. In diesem persönlichen Beratungsgespräch erfährst du alles Wichtige rundum deine perfekten Augenbrauen. Wir reden über Behandlungsmöglichkeiten und du hast die Möglichkeit, all deine offenen Fragen zu klären.'
          }
        },
        {
          type: 'cta',
          classNames: 'column is-12-mobile is-12-tablet',
          config: {
            dark: false,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
